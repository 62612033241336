<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="p-5" v-if="loading">Geräte werden geladen...</div>
                    <div class="p-5" v-if="devices.length === 0 && !loading">Du hast noch keine Geräte registriert...
                    </div>
                    <div class="card-body px-0 pt-0 pb-2" v-if="devices.length > 0 && !loading">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Geräte ID
                                    </th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Bezeichnung
                                    </th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Zuletzt gesehen
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="align-items-center" v-for="device in devices" :key="device.deviceUid">
                                    <td><h6 class="mb-0 text-sm">{{ device.deviceUid }}</h6></td>
                                    <td><h6 class="mb-0 text-sm">{{ device.deviceName }}</h6></td>
                                    <td><h6 class="mb-0 text-sm">{{ device.lastSeenAt }}</h6></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useUserStore} from "@/store/user";
import axios from "axios";

export default {
    name: "device-table",
    data() {
        return {
            loading: true,
            devices: []
        }
    },
    components: {},
    methods: {
        async getDevices() {
            const user = useUserStore();
            const response = await axios.get('https://api.airlog.app/dashboard/devices', {
                headers: {
                    "user-token": user.token,
                    "user-id": user.user.uid
                }
            });
            this.devices = response.data;
            this.loading = false;
        }
    },
    mounted() {
        this.getDevices();
    }
};
</script>
