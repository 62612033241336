import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Profile from "@/views/Profile.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Logout from "@/views/Logout.vue";
import Logbook from "@/views/Logbook.vue";
import JumpDetails from "@/views/JumpDetails.vue";
import Devices from "@/views/Devices.vue";
import { useUserStore } from "@/store/user";


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/logbook",
    name: "Logbook",
    component: Logbook
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout
  },
  {
    path: "/devices",
    name: "Devices",
    component: Devices
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp
  },
  {
    path: "/jump/:id",
    name: "Jumpdetails",
    component: JumpDetails
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active"
});

router.beforeEach(async (to) => {

  const publicPages = ["/sign-in", "/sign-up"];
  const authRequired = !publicPages.includes(to.path);
  const userStore = useUserStore();
  let user = userStore.loggedIn;
  if (authRequired && !user) {
    //  auth.returnUrl = to.fullPath;
    return "/sign-in";
  }
});


export default router;
