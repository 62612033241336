/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import { createPinia } from 'pinia';
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
//import VueGoogleMaps from '@fawmi/vue-google-maps'


const pinia = createPinia();
pinia.use(piniaPluginPersistedState)

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(pinia);
appInstance.use(SoftUIDashboard);
/*
appInstance.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCpPGxo78yOmV3U6Q7HoG_w8GwNCIZNb3Q',
    },
});*/
appInstance.mount("#app");
