<template>
    <footer class="py-3 footer">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-lg-between">
                <div class="mb-4 col-lg-6 mb-lg-0">
                    <div
                            class="text-sm text-center copyright text-muted text-lg-start"
                    >
                        ©
                        2022 - {{ new Date().getFullYear() }}, made with
                        <i class="fa fa-heart text-danger" ></i> by Airlog
                    </div>
                </div>
                <div class="col-lg-6">
                    <ul
                            class="nav nav-footer justify-content-center justify-content-lg-end"
                    >
                        <li class="nav-item">
                            <a
                                    href="https://airlog.app/about"
                                    class="nav-link text-muted"
                                    target="_blank"
                            >Über uns</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                    href="https://blog.airlog.app"
                                    class="nav-link text-muted"
                                    target="_blank"
                            >Blog</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                    href="https://airlog.app/impressum"
                                    class="nav-link pe-0 text-muted"
                                    target="_blank"
                            >Impressum</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                    href="https://airlog.app/datenschutz"
                                    class="nav-link pe-0 text-muted"
                                    target="_blank"
                            >Datenschutz</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "app-footer",
};
</script>
