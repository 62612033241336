import {defineStore} from "pinia";
import {
    signInWithEmailAndPassword,
    setPersistence,
    signOut,
    browserSessionPersistence,
} from "firebase/auth";
import {auth} from "@/firebaseConfig"

export const useUserStore = defineStore("user", {
    persist: true,
    state: () => ({
        user: null,
        loggedIn: false,
        token: null,
        refreshToken: null,
    }),
    getters: {
        getUser(state) {
            return state.user;
        },
        isLoggedIn(state) {
            return state.loggedIn;
        }
    },
    actions: {
        async logOut() {
            await signOut(auth);
            this.user = null;
            this.loggedIn = false;
        },
        async logIn({email, password}) {
            await setPersistence(auth, browserSessionPersistence);
            const response = await signInWithEmailAndPassword(auth, email, password);
            console.log("response", response);
            if (response) {
                this.user = response.user;
                this.token = response._tokenResponse.idToken;
                this.refreshToken = response._tokenResponse.refreshToken;
                console.log("token", this.token);
                this.loggedIn = true;
            } else {
                throw new Error("login failed");
            }
        }
    }
});