<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="card mb-4">
                        <div class="card-header pb-0">
                            <h6>Sprungbuch</h6>
                        </div>
                        <div class="card-body px-0 pt-0 pb-2">
                            <div class="table-responsive p-0">
                                <table class="table align-items-center mb-0">
                                    <thead>
                                    <tr>
                                        <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                        >
                                            Sprung #
                                        </th>
                                        <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                                        >
                                            Dropzone
                                        </th>
                                        <th
                                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                        >
                                            Sprungdaten
                                        </th>
                                        <th
                                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                        >
                                            Kommentar
                                        </th>
                                        <th class="text-secondary opacity-7"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="jump in data" :key="jump.id">
                                        <td>
                                            <div class="d-flex px-2 py-1">

                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-sm">{{ jump.jumpNo }}</h6>
                                                    <p class="text-xs text-secondary mb-0">
                                                        {{ formatDate(jump.jumpDate) }}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p class="text-xs font-weight-bold mb-0">{{
                                                jump.dropzone.dropzoneName
                                                }}</p>
                                            <p class="text-xs text-secondary mb-0">{{ jump.plane }}</p>
                                            <p class="text-xs text-secondary mb-0">{{ jump.altitude }}m</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <soft-badge v-if="jump.airLogFileName != null" color="success"
                                                        variant="gradient" size="sm"
                                            >Verfügbar
                                            </soft-badge
                                            >
                                            <soft-badge v-if="jump.airLogFileName == null" color="secondary"
                                                        variant="gradient" size="sm"
                                            >Nicht Verfügbar
                                            </soft-badge
                                            >
                                        </td>
                                        <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                >{{ jump.comment }}</span
                >
                                        </td>
                                        <td class="align-middle">
                                            <a
                                                    :href="getJumpLink(jump.jumpId)"
                                                    class="text-secondary font-weight-bold text-xs"
                                                    data-toggle="tooltip"
                                                    data-original-title="Edit user"
                                            >Sprung ansehen</a
                                            >
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useUserStore} from "@/store/user";
import axios from "axios";
import SoftBadge from "@/components/SoftBadge.vue";

export default {
    name: "logbook",
    components: {
        SoftBadge,
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', {dateStyle: 'long', timeStyle: 'long'}).format(date);
        },
        getJumpLink(id) {
            return "/jump/" + id;
        },
        async getLogbook() {
            const user = useUserStore();
            const response = await axios.get('https://api.airlog.app/dashboard/logbook', {
                headers: {
                    "user-token": user.token,
                    "user-id": user.user.uid
                }
            });
            console.log(response.data);
            this.data = response.data;
        }
    },
    data() {
        return {
            data: null,
        };
    },
    mounted() {
        this.$store.state.isAbsolute = true;
        this.getLogbook()
    },
};
</script>
