import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'



//TODO: add firebase config to env vars !!
const firebaseConfig = {
  apiKey: "AIzaSyDmWUexU34eFyL_DHVm3ncn9-xOURDAwg8",
  authDomain: "jumplogger-70bc3.firebaseapp.com",
  databaseURL: "https://jumplogger-70bc3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "jumplogger-70bc3",
  storageBucket: "jumplogger-70bc3.appspot.com",
  messagingSenderId: "1017838234856",
  appId: "1:1017838234856:web:a2d571f1e59bbe6afdad5d"
};
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth()


export { app, auth }
// https://blog.logrocket.com/authentication-vue-3-firebase/