<template>
    <div class="py-4 container-fluid" v-if="data != null">
        <div class="row">
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card h-100">
                    <div class="p-3 card-body">
                        <GoogleMap v-if="data!=null" api-key="AIzaSyCpPGxo78yOmV3U6Q7HoG_w8GwNCIZNb3Q"
                                   style="width: 100%; height: 500px"
                                   mapTypeId="satellite"
                                   :disableDefaultUI="true"
                                   :center="center" :zoom="15">
                            <Polyline :options="polyOptions"/>
                            <Marker :options="markerOptions"/>
                            <CustomControl position="LEFT">
                                <div class="card h-100">
                                    <div class="p-3 card-body">
                                        {{ path[dataPosition].time }}<br>
                                        {{ path[dataPosition].heading }}<br>
                                    </div>
                                </div>
                            </CustomControl>
                            <!--<InfoWindow :options="{ position: this.path[dataPosition], content: 'Hello World!' }"/>-->

                        </GoogleMap>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card h-100">
                    <div class="p-3 card-body">
                        <div class="row">
                            <apexchart
                                    v-if="data !== null"
                                    height="150"
                                    type="line"
                                    :options="chartOptions"
                                    :series="series"
                            />
                            <apexchart
                                    v-if="data !== null"
                                    type="line"
                                    height="150"
                                    :options="chartOptions2"
                                    :series="seriesHeight"
                            />
                            <apexchart
                                    v-if="data !== null"
                                    type="line"
                                    height="150"
                                    :options="chartOptions2"
                                    :series="seriesVSpeed"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-4 container-fluid" v-if="data != null">
        <div class="row">
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card h-100">
                    <div class="p-3 card-body">
                        <div class="p-3 pb-0 card-header">
                            <h6 class="mb-0">Position</h6>
                        </div>
                        <Slider v-if="data !=null" v-model="dataPosition" :min="0" :max="this.path.length"
                                @slide="this.update"
                                tooltipPosition="bottom"
                                :format="format"/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card h-100">
                    <div class="p-3 card-body">
                        <h6 class="mb-0">Trimmen</h6>
                        <Slider v-if="data !=null" v-model="rangeValue" :min="0" :max="data.data.length"
                                @slide="this.updateRange" tooltipPosition="bottom"
                                :format="formatRange"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="btn btn-primary" @click="this.doPlay(!this.play)">{{
        this.play ? 'Pause' : 'Play'
        }}
    </button>
    <div class="py-4 container-fluid" v-if="data != null">
        <div class="row">
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card h-100">
                    <div class="p-3 card-body">
                        <div class="p-3 pb-0 card-header">
                            <h6 class="mb-0">Sprungdetails</h6>
                        </div>
                        Datum: <b>{{data.jump.jumpDateParsed}}</b><br>
                        Sprungplatz: <b>{{data.dropzone.dropzoneName}}</b><br>
                        Sprung <b>#{{data.jump.jumpNo}}</b>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card h-100">
                    <div class="p-3 card-body">
                        <div class="p-3 pb-0 card-header">
                            <h6 class="mb-0">Sprung teilen</h6>
                        </div>
                        <qrcode-vue :value="getShareUrl()" size="100" level="M" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useUserStore} from "@/store/user";
import axios from "axios";
import {GoogleMap, Polyline, Marker, CustomControl} from "vue3-google-map";
import Slider from '@vueform/slider'
import VueApexCharts from "vue3-apexcharts";
import QrcodeVue from 'qrcode.vue'


export default {
    name: "JumpDetails",
    components: {GoogleMap, Polyline, Marker, CustomControl, Slider, apexchart: VueApexCharts,QrcodeVue},
    data() {
        return {
            center: {lat: 51.093048, lng: 6.842120},
            data: null,
            play: false,
            path: [],
            pathStart: 0,
            pathEnd: 0,
            rangeValue: [0, 100],
            dataPosition: 0,
            rangePosition: 0,
            polyOptions: null,
            markerOptions: null,
            chartOptions: {
                chart: {
                    id: "chartHeight",
                    group: "test",
                },
                xaxis: {
                    type: 'numeric'
                },
                events: {
                    click: function (event, chartContext, config) {
                        console.log(event, chartContext, config);
                        // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                    }
                }
            },
            chartOptions2: {
                chart: {
                    id: "chartHeight2",
                    group: "test",
                },
                xaxis: {
                    type: 'numeric'
                },
            },
            series: [],
            seriesHeight: [],
            seriesVSpeed: []

        }
    },
    methods: {
        doPlay(value) {
            this.play = value;
            if (this.dataPosition + 1 > this.path.length) {
                this.dataPosition = 0;
            }
            this.update(this.dataPosition + 1);
            if (this.play) {
                setTimeout(() => {
                    this.doPlay(this.play);
                }, 100);
            }
        },
        formatRange(value) {
            console.log(value);
            if (this.path !== undefined && this.path.length > 0 && Math.round(value) < this.data.data.length)
                return this.data.data[Math.round(value)].alt + "m";
            else return "";
        },
        getShareUrl() {
            return "https://jump.airlog.app/"+this.$route.params.id;
        },
        format(value) {
            if (this.path !== undefined && this.path.length > 0)
                return this.path[Math.round(value)].alt + "m<br>" + this.path[Math.round(value)].speed + "<br>" + this.path[Math.round(value)].sink;
            else return "";
        },
        updateRange(value) {
            this.pathStart = value[0];
            this.pathEnd = value[1];
            this.path = [];
            this.data.data.slice(this.pathStart, this.pathEnd).forEach(item => {
                if (Number.isFinite(parseFloat(item.lat)) && Number.isFinite(parseFloat(item.lon))) {
                    const newItem = item;
                    newItem.lat = parseFloat(item.lat);
                    newItem.lng = parseFloat(item.lon);
                    this.path.push(newItem);
                }
            });
            this.polyOptions = {
                path: this.path,
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
            };
        },
        update(value) {
            this.dataPosition = value;
            this.center = this.path[value];
            this.markerOptions = {position: this.path[value]}
            window.ApexCharts.exec("chartHeight", "toggleDataPointSelection", [0, 500]);
        },
        async getJump() {
            const user = useUserStore();
            const response = await axios.get('https://api.airlog.app/dashboard/logbook/' + this.$route.params.id, {
                headers: {
                    "user-token": user.token,
                    "user-id": user.user.uid
                }
            });
            let seriesData = [];
            let seriesDataHeight = [];
            let serienDataVSpeed = [];
            let count = 0;
            response.data.data.forEach(item => {
                if (Number.isFinite(parseFloat(item.lat)) && Number.isFinite(parseFloat(item.lon))) {
                    const newItem = item;
                    newItem.lat = parseFloat(item.lat);
                    newItem.lng = parseFloat(item.lon);
                    this.path.push(newItem);
                    seriesData.push({x: ++count, y: item.speed});
                    seriesDataHeight.push({x: ++count, y: item.alt});
                    serienDataVSpeed.push({x: ++count, y: item.sink});
                }
            });
            this.polyOptions = {
                path: this.path,
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
            };
            this.center = {
                lat: this.path[0].lat,
                lng: this.path[0].lng
            };
            this.markerOptions = {position: this.path[this.dataPosition]}
            this.data = response.data;
            this.rangeValue = [0, this.data.data.length];
            this.pathEnd = this.data.data.length;
            this.series = [
                {
                    name: "speed",
                    data: seriesData,
                },
            ];
            this.seriesHeight = [
                {
                    name: "Altitude",
                    data: seriesDataHeight,
                },
            ];
            this.seriesVSpeed = [
                {
                    name: "height",
                    data: serienDataVSpeed,
                },
            ];
        }
    },
    mounted() {
        this.$store.state.isAbsolute = true;
        this.getJump();
    }
    ,
}
</script>
<style src="@vueform/slider/themes/default.css"></style>

<style scoped>

</style>