<template>
    <div class="container-fluid" v-if="!this.$store.state.loggedIn && data != null">
        <div
                class="mt-4 page-header min-height-300 border-radius-xl"
                :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
        >
            <span class="bg-gradient-success opacity-6"></span>
        </div>
        <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
            <div class="row gx-4">
                <div class="col-auto">
                    <div class="avatar avatar-xl position-relative">
                        <img
                                :src="data.image"
                                alt="profile_image"
                                class="shadow-sm w-100 border-radius-lg"
                        />
                    </div>
                </div>
                <div class="col-auto my-auto">
                    <div class="h-100">
                        <h5 class="mb-1">{{ data.firstname }} {{ data.lastname }}</h5>
                        <p class="mb-0 text-sm font-weight-bold"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-4 container-fluid" v-if="data != null">
        <div class="mt-3 row">
            <div class="col-12 col-md-6 col-xl-4">
                <div class="card h-100">
                    <div class="p-3 pb-0 card-header">
                        <h6 class="mb-0">Persönliche Einstellungen</h6>
                    </div>
                    <div class="p-3 card-body">
                        <h6 class="text-xs text-uppercase text-body font-weight-bolder">
                            Deine Privaten Einstellungen
                        </h6>
                        <ul class="list-group">
                            <li class="px-0 border-0 list-group-item">
                                {{ data.firstname }} {{ data.lastname }}
                            </li>
                            <li class="px-0 border-0 list-group-item">
                                {{ data.birthday }}
                            </li>
                            <li class="px-0 border-0 list-group-item">
                                {{ data.email }}
                            </li>
                            <li class="px-0 border-0 list-group-item">
                                {{ data.phone }}
                            </li>
                        </ul>
                        <h6
                                class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
                        >
                            Adresse
                        </h6>
                        <ul class="list-group">
                            <li class="px-0 border-0 list-group-item">
                                {{ data.address[0].street }} {{ data.address[0].number }}
                            </li>
                            <li class="px-0 border-0 list-group-item">
                                {{ data.address[0].zip }} {{ data.address[0].country1 }}
                            </li>
                            <li class="px-0 border-0 list-group-item">
                                {{ data.address[0].country2 }}
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <profile-info-card
                        title="Öffentliches Profil"
                        :description="data.publicProfile.publicDesc"
                        :info="{
            fullName: data.publicProfile.publicName,
            mobile: data.publicProfile.publicPhone,
            email: data.publicProfile.publicEmail,
            location: data.publicProfile.publicLocation,
          }"
                        :social="[
            {
              link: 'https://www.facebook.com/'+data.publicProfile.publicSocialFacebook,
              icon: faFacebook,
            },
            {
              link: 'https://twitter.com/'+data.publicProfile.publicSocialTwitter,
              icon: faTwitter,
            },
            {
              link: 'https://www.instagram.com/'+data.publicProfile.publicSocialInstagram,
              icon: faInstagram,
            },
          ]"
                        :action="{
            route: 'javascript:;',
            tooltip: 'Bearbeiten',
          }"
                />
            </div>
            <div class="mt-4 col-12 col-xl-4 mt-xl-0">
                <div class="card h-100">
                    <div class="p-3 pb-0 card-header">
                        <h6 class="mb-0">Account</h6>
                    </div>
                    <div class="p-3 card-body">
                        <ul class="list-group">
                            <li
                                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                            >
                                Passwort ändern
                            </li>
                            <li
                                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                            >
                                Login E-Mail ändern
                            </li>
                            <li
                                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                            >
                                <router-link
                                    :to="{ name: 'Logout' }"

                                >Logout
                                </router-link
                                >
                            </li>
                            <li
                                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                            >
                                Account löschen
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {
    faFacebook,
    faTwitter,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import axios from 'axios'
import {useUserStore} from "@/store/user";
import Logout from "@/views/Logout.vue";

export default {
    name: "ProfileOverview",
    computed: {
        Logout() {
            return Logout
        }
    },
    components: {
        ProfileInfoCard,
    },
    methods: {
        async getProfile() {
            const user = useUserStore();
            const response = await axios.get('https://api.airlog.app/dashboard/profile', {
                headers: {
                    "user-token": user.token,
                    "user-id": user.user.uid
                }
            });
            this.data = response.data;
        }
    },
    data() {
        return {
            data: null,
            showMenu: false,
            sophie,
            marie,
            ivana,
            peterson,
            nick,
            img1,
            team1,
            team2,
            team3,
            team4,
            img2,
            img3,
            faFacebook,
            faTwitter,
            faInstagram,
        };
    },

    mounted() {
        this.$store.state.isAbsolute = true;
        setNavPills();
        setTooltip(this.$store.state.bootstrap);
        this.getProfile()
    },
    beforeUnmount() {
        this.$store.state.isAbsolute = false;
    },
};
</script>
