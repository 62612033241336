<template>
    <div class="page-header min-vh-75">
        <div class="container">
            <div class="row">
                <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                    <form role="form" class="text-start" action="#"
                          @submit.prevent="signOut">
                        <p>Möchtest du dich wirklich ausloggen?</p>
                        <soft-button
                                class="my-4 mb-2"
                                variant="gradient"
                                color="success"
                                full-width
                        >Ja ausloggen
                        </soft-button>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import {useUserStore} from "@/store/user";
import router from "@/router";

export default {
    name: "ProfileOverview",
    components: {
        SoftButton
    },
    data() {

    },

    mounted() {

    },
    beforeUnmount() {

    },
    methods: {
        async signOut() {
            try {
                const userStore = useUserStore();
                await userStore.logOut();
                console.log("Logged out");
                await router.push({name: "Dashboard"});
            } catch (e) {
                console.log(e);
            }
        }
    }
};
</script>
